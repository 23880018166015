import './styles/main.scss'
import './jquery-globals'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel'

$(document).ready(function($){
    const $general = $('#gs-general');
    const $header = $('#gs-header');
    var doc = document.documentElement;
    var w = window;
    var prevScroll = w.scrollY || doc.scrollTop;
    var curScroll;
    var direction = 0;
    var prevDirection = 0;
    var contrastValue = sessionStorage.getItem('gs-contrast') == 'true' ? 'true' : 'false';
    const $galeria = $( '#galeria-social' );

    const refreshGaleriaSocial = search => {
        $galeria.addClass( 'loading' );
        $( 'button, input', $galeria ).attr( 'disabled', true );

        $.ajax( {
            url: $galeria.data( 'ajaxurl' ), 
            method: 'POST',
            data: {
                action: 'mosaico_galeria_social',
                search 
            },
            success: html => {
                $galeria.removeClass( 'loading' );
                $( 'button, input', $galeria ).attr( 'disabled', false );
                $('.gs-result', $galeria).html( html );
            }
        });
    };

    const contrast = () => {
        if (contrastValue == 'true') {
            $('body').addClass('gs-apply-contrast');
        } else {
            $('body').removeClass('gs-apply-contrast');
        }
    }

    const setContrast = () => {
        contrastValue = sessionStorage.getItem('gs-contrast') == 'true' ? 'true' : 'false';

        if (contrastValue == 'true') {
            $('body').removeClass('gs-apply-contrast');
            sessionStorage.setItem('gs-contrast', 'false');
        } else {
            $('body').addClass('gs-apply-contrast');
            sessionStorage.setItem('gs-contrast', 'true');
        }
    }

    const carousel = () => {
        $('.gs-carousel', $general).each(function() {
            var $carousel = $(this),
                $wrapper = $('.gs-wrapper', $carousel),
                options = {
                    items: 1,
                    loop: true,
                    navContainer: $carousel,
                    nav: false,
                    dots: true,
                    responsive: {
                        0: {
                            autoHeight: true
                        },
                        767: {
                            autoHeight: false
                        }
                    }
                };

            if ($carousel.hasClass('gs-text')) {
                options.nav = true;
                options.navContainer = $('.gs-navigation', $carousel);
                options.dotsContainer = $('.gs-navigation .gs-traces', $carousel);
                options.onInitialized = (e) => {
                    $('.gs-navigation .gs-traces', $carousel).attr('data-total', `${e.item.count}`.padStart(2, 0));
                    $('.gs-navigation .gs-traces', $carousel).attr('data-current', '01');
                };
                options.onChanged = (e) => {
                    $('.gs-navigation .gs-traces', $carousel).attr('data-current', `${e.item.index - 1}`.padStart(2, 0));
                }
            }

            if ($carousel.parents('.gs-partners').length) {
                options.items = 1;
                options.margin = 35;
                options.autoWidth = true;
                options.nav = true;
                options.loop = false;
                options.dots = false;
                options.rewind = false;
            }

            $($wrapper).owlCarousel(options);
        })
    }

    const modal = {
        open: (video) => {
            $('#gs-modal-video')
                .addClass('gs-active')
                .find('.gs-video iframe')
                .attr('src', video)

            $('body').addClass('gs-video-open')
        },
        close: () => {
            $('#gs-modal-video').removeClass('gs-active')

            $('body').removeClass('gs-video-open')

            setTimeout(() => {
                $('#gs-modal-video .gs-video iframe').attr('src', '')
            }, 600)
        }
    }

    const share = {
        open: (item) => {
            item.parent().addClass('gs-share-open')
        },
        close: (target) => {
            if (target) {
                target.parents('.gs-share').removeClass('gs-share-open');
            } else {
                $('.gs-share').removeClass('gs-share-open');
            }
        }
    }

    const copyToClipboard = function() {
        const inputc = document.body.appendChild(document.createElement("input"));
        inputc.value = $(this).data('copy-url');
        inputc.select();
        document.execCommand('copy');
        inputc.parentNode.removeChild(inputc);
    }

    const header = () => {
        if ($(window).scrollTop() >= $header.outerHeight()) {
            $header.addClass('gs-fixed');
        } else {
            $header.removeClass('gs-fixed');
        }

        curScroll = w.scrollY || doc.scrollTop;

        if (curScroll > prevScroll) {
          direction = 2;
        } else if (curScroll < prevScroll) {
          direction = 1;
        }

        if (direction !== prevDirection) {
            if (direction === 2 && curScroll > $header.outerHeight()) {
                $header.addClass('gs-hide');
                prevDirection = direction;
            } else if (direction === 1) {
                $header.removeClass('gs-hide');
                prevDirection = direction;
            }
        }

        prevScroll = curScroll;
      };

    // INIT
    carousel();
    header();
    contrast();

    // EVENTS
    $('#gs-header .gs-items a, #gs-footer .gs-items a', $general).on('click', () => {
        $('body').removeClass('gs-menu-open')
    })

    $('.gs-bt-menu', $general).on('click', () => {
        $('body').toggleClass('gs-menu-open')
    })

    $('.gs-bt-contrast', $general).on('click', () => {
        setContrast();
    })

    $general.on('click', '.gs-open-modal-video', (e) => {
        modal.open($(e.target).data('video'));
        share.close();
    })

    $('#gs-modal-video .gs-close').on('click', (e) => {
        modal.close()
    })

    $(document).on('click', (e) => {
        if($(e.target).closest('#gs-modal-video').length) {
            modal.close()
        }
    })

    $('.gs-like', $general).on('click', (e) => {
        $(e.target).parents('.gs-item').toggleClass('gs-active');
    })

    $general.on('click', '.gs-share >button', (e) => {
        share.open($(e.target))
    })

    $general.on('click', '.gs-share .gs-copy-link', copyToClipboard)


    $general.on('click', '.gs-share .gs-close', (e) => {
        share.close($(e.target));
    })

    $('.gs-social-gallery').on('click', '.gs-item .gs-image', (e) => {
        $(e.target).parents('.gs-item').toggleClass('gs-active');
    })

    $( 'button.gs-latest', $galeria ).on( 'click', e => {
        e.preventDefault();
        refreshGaleriaSocial();
    } );
  
    $( 'form', $galeria ).on( 'submit', e => {
        e.preventDefault();
        refreshGaleriaSocial( $( 'form input[name=s]', $galeria ).val() );
    } );

    $(window).on('scroll', () => {
        header();
    });
})
